import { Component, OnInit, Input } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EventsComponent implements OnInit {


  public photoArray = [
    { src: "../assets/images/carousel/image001.jpg" },
    { src: "../assets/images/carousel/image002.jpg" },
    { src: "../assets/images/carousel/image003.jpg" },
    { src: "../assets/images/carousel/image007.jpg" },
    { src: "../assets/images/carousel/image008.png" },
    { src: "../assets/images/carousel/image010.jpg" },
    { src: "../assets/images/carousel/image013.jpg" },
    { src: "../assets/images/carousel/image014.jpg" },
    // { src: "../assets/images/carousel/image022.JPG" },
    { src: "../assets/images/carousel/image023.JPG" },
    { src: "../assets/images/carousel/image024.JPG" },
    // { src: "../assets/images/carousel/image027.JPG" },
    // { src: "../assets/images/carousel/image028.JPG" },
    { src: "../assets/images/carousel/image029.JPG" },
    { src: "../assets/images/carousel/image031.JPG" },
    { src: "../assets/images/carousel/image033.JPG" },
    // { src: "../assets/images/carousel/image034.JPG" },
    // { src: "../assets/images/carousel/image035.JPG" }
  ]

  public currentSlide = 0;

  constructor() { }

  ngOnInit(): void {
    window.location.href="https://www.clearconference.org/";
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.photoArray.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.photoArray.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }
}
