import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AppRoutingModule } from './app-routing.module';
import { PresidentComponent } from './president/president.component';
import { BoardComponent } from './board/board.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { EventsComponent } from './events/events.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailServiceService } from './services/email-service.service';
import { HttpClientModule } from '@angular/common/http';
import { FusionComponent } from './fusion/fusion.component';
//import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NavBarComponent,
    PresidentComponent,
    BoardComponent,
    SponsorsComponent,
    ContactComponent,
    FooterComponent,
    EventsComponent,
    FusionComponent
  ],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //RecaptchaV3Module
  ],
  providers: [
    EmailServiceService,
    //{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LdEZeUaAAAAACQ6QYxDtl15_ZI5i0MwTMDcGtOr" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
