import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { EmailServiceService } from '../services/email-service.service';
//import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @ViewChild(ClrForm, {static: true}) clrForm;
  public emailForm;
  public submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  public newEmail;
  public registeredStatus;
  public emailSuccess;
  public sentSuccess = false;
  public sentFail = false;
  @ViewChild('recaptcha', {static: true}) recaptchaElement: ElementRef;

  

  constructor(private emailService: EmailServiceService) { //, private recaptchaV3Service: ReCaptchaV3Service) {
    this.emailForm = new FormGroup({
      fullName: new FormControl('', Validators.required),
      emailAddress: new FormControl('', Validators.required),
      emailSubject: new FormControl('', Validators.required),
      emailMsg: new FormControl('', Validators.required),
    });
   }

  ngOnInit(): void {
    this.addRecaptchaScript();
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LdEZeUaAAAAACQ6QYxDtl15_ZI5i0MwTMDcGtOr',
      'callback': (response) => {
          console.log(response);
      }
    });
  }

  onSubmit() {
    if (this.emailForm.invalid) {
      this.clrForm.markAsTouched();
    } else {
      this.emailSuccess = false;
      this.submitBtnState = ClrLoadingState.LOADING;
      let emailObj = (this.emailForm.value);
      //console.log(emailObj.fullName);
      let emailData = {
        // "fullName" : emailObj.fullName,
        // "emailSuject" : emailObj.emailSubject,
        // "emailAddress" : emailObj.emailAddress,
        // "emailMsg" : emailObj.emailMsg
      };
      this.emailSuccess = true;
      //this.submitBtnState = ClrLoadingState.DEFAULT;
      this.emailService.sendEmail(emailObj)
        .subscribe(
          data => {
            //console.log(data);
            console.log('Success', data);
            this.emailForm.reset();
            this.emailForm.get('fullName').setValue('');
            this.emailForm.get('emailSubject').setValue('');
            this.emailForm.get('emailAddress').setValue('');
            this.emailForm.get('emailMsg').setValue('');
            this.sentSuccess = true;
            this.submitBtnState = ClrLoadingState.DEFAULT;
          },
          error => {
            //console.log("Error",error);
            this.emailForm.reset();
            this.emailForm.get('fullName').setValue('');
            this.emailForm.get('emailSubject').setValue('');
            this.emailForm.get('emailAddress').setValue('');
            this.emailForm.get('emailMsg').setValue('');
            this.sentSuccess = true;
            //this.sentFail = true;
            this.submitBtnState = ClrLoadingState.DEFAULT;
          }
        )
    }    
  }
  
}
