<div class="clr-row" style="padding: 0px; margin: 0px; border: 0px; background-color: white;">
  <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12">&nbsp;</div>
  <div class="clr-col-lg-8 clr-col-md-8 clr-col-sm-12">
    <div class="clr-row" style="border: 0px; padding: 0px; margin: 0px; text-align: center; display: -webkit-flex; display: flex;">
      <div class="clr-col-12" style="text-align: center;">
          <h1 class="header-text-blue">A Message From Our President</h1>
      </div>
      <div class="clr-col-12 subtext-blue" style="text-align: justify;">
        <span>
          <p class="text-blue">The law enforcement profession is continually changing and the mission of FLEPRU is to ensure we stay up to date with theses changes.  I have been a member of FLEPRU and attending the annual conference for the last sixteen (16) years.  The last ten years, I have served on the Board of Directors in various capacities, and as the President for the last four years.  Early on, I decided that I did not want to be one of the many people that sit on the sidelines and only complain about laws and the negative changes occurring within our profession.  I made it my priority to get involved and help bring about change, and FLEPRU was the mechanism by which I began to do so.  FLEPRU has continued to thrive over the last three decades because the incredible Board of Directors and all the volunteers past and present share in this same philosophy.</p>
          <p class="text-blue">One of my guiding principles is I always try to make a difference within my current assignment, and always do my best to leave it better than it was before.  Since becoming the President of FLEPRU, this has not been a hard task to accomplish because of the many great men and women that led this organization to what it has become today:  the premier law enforcement conference within the State of Florida and the country.  Year after year, FLEPRU ensures that our annual training conference addresses and instructs on the most current events, trends, and topics within our profession.  Many of the successes I have achieved in my career are a direct result from interaction with the men and women of this organization, and the network of law enforcement professionals and retailers I have met over the years.  I confidently look forward to the future of our professions, and I am eager to continue in assisting others within our profession through FLEPRU’s annual training conferences.</p>
          <p class="text-blue">In the last few years, the law enforcement community has been under the microscope and is being scrutinized for the actions and/or perceived actions of a few.  These trying times should in no way change our moral compass of right and wrong the great work we do day in and day out.  While the actions of a few in no way define us, it does offer us the opportunity to discuss change.  I have seen quite a bit of change during my twenty-five (25) year career and have been able to adapt to these changes.  With change we all can be somewhat resistant at times but looking back at many of the changes that have occurred over my career as a Detective or Detective Sergeant worked out for the better.  I am confident that we as law enforcement professionals will overcome these recent events, because at the end of the day we are the last line of defense between good and evil.</p>
          <p class="text-blue">
            Our world changed in early 2020 with the onset of the COVID-19 pandemic.  Our profession requires us to be on the front lines 24/7 and that includes putting ourselves in harm’s way in order to fulfill or duty.  While doing, so many in our profession lost their lives.  For those who paid the ultimate sacrifice, you will never be forgotten and are forever in our prayers.  Once again, when faced with adversity amid the pandemic, our profession stood up to this invisible threat and continued to work with courage and honor.
          </p>
          <p class="text-blue">
            In addition to the trials of the pandemic, our country has been faced with a drastic increase in civil unrest which we are called upon to quell. The civil unrest and the COVID-19 pandemic will require us to change the ways in which we investigate and approach policing.  The State of Florida took a bold step forward in addressing civil unrest, protecting law enforcement officers, and protecting the rights of those who engage in peaceful protests versus riots with the recent passing of the anti-rioting bill H.B. 1 which was signed into law by Governor DeSantis on April 19, 2021.  During these ever-changing times, we live in we must never forget the importance of debate and differences in opinions these are the fundamentals principles that have shaped our democracy.  We as a nation, state, and individuals all need to work on moving more towards the middle rather than continue moving further and further apart which only divides us further.  Through FLEPRU, I am once again afforded the opportunity to work on best practices related to our professions.
          </p>
        </span>
      </div>
      <div class="clr-col-12" style="height: 20px;">&nbsp;</div>
    </div>  
  </div>
  <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12">&nbsp;</div>
</div>


<!-- <div class="clr-row">
  <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
  <div class="clr-col-lg-8 clr-col-md-8 clr-col-sm-12">
      <div style="padding: 10px;">
          <div class="card" style="border: 0px;">
            <div class="card-header" style="font-size: 40px; text-align: left; vertical-align: bottom; line-height: 40px;">
              <h1 style="line-height: 20px;"><img src="..\assets\images\Emblem_blue.png" style="width: 40px;">President's Message</h1>
            </div>
            <div class="card-block">
              <div class="card-text">
                <p>In May of 1987, a group of property recovery detectives within the Tampa Bay area, formed the first property recovery unit in the United States. They gathered in an attempt to answer an age old problem plaguing law enforcement, “How can law enforcement increase the recovery of stolen property and place the guilty in jail?” The detectives realized that property crimes were continually increasing and the resources needed to attack this investigation focused on the thief and his acquired or stolen property. This group of detectives realized the need to utilize the “Dynamic” approach which focuses on property rather than relying on the arrest of the criminal. The detectives realized that a “Fence” was a major part of the problem and by using a “Fence” the criminal was able to convert the stolen property to cash. The “Fence” would bear the burden of selling the property to new property owners. Sometimes the new owners were unsuspecting of the properties origin, due to the reputation of the seller.</p>
                <p>This group recognized that information exchange on a statewide level was necessary to effectively track stolen goods, match recovered goods to victims and apprehend criminals. The group decided to formally organize the “Florida Law Enforcement Property Recovery Unit” (FLEPRU), as the group is known, has divided the state into five regions, each have a District Director.</p>
                <p>Each director is responsible for providing members and other Law Enforcement Agencies with local, state and federal intelligence information. FLEPRU holds statewide training seminars and conferences annually. Statewide meetings provide current information on changes in the laws which affect property crimes and property recovery as well as in subject areas ranging from operating a property recovery unit to second hand dealer and car title loan transactions. FLEPRU seminars utilize guest instructors such as state legislators, judges, private sector executives and experts in the field of property investigation and recovery. FLEPRU membership is open to all law enforcement agencies and personnel along with approved non-law enforcement investigators, such as in the insurance industry. Small and large law enforcement agencies alike have benefited from membership in FLEPRU. FLEPRU has no annual membership fees and is a non-profit corporation.</p>
                <p>The Goals of FLEPRU are to:</p>
                <ol>
                  <li>Recruit members from all municipal, county, state, and federal law enforcement agencies within the state of Florida to encourage mutual cooperation.</li>
                  <li>Raise the recovery percentile of stolen property and its return to victims.</li>
                  <li>Enact legislation/lobbying to solicit new Legislation, and deliver the criminal harsher Penalties, assuring the criminal does not convert stolen property to cash.</li>
                  <li>Help establish an FCIC computer based tracking system to track stolen property as well as dealers of stolen property across county lines.</li>
                  <li>Provide educational conferences and seminars to the newly assigned investigator as well as the seasoned veteran.</li>
                </ol>
                <p style="text-align: center; font-size: 20px;">In February, 1991, FLEPRU received the “Exemplary State and Local Award” which is awarded nationally and presented by Rutgers University. The award is based on innovation and achievement.</p>
              </div>
            </div>
          </div>
        </div>
  </div>
  <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
</div> -->
