<div class="clr-row" style="margin: 0px; padding: 0px; border: 0px; min-height: calc(100vh - 260px); background-color: white;">
    <div class="clr-col-lg-2 clr-col-md-0 clr-col-sm-0" style="background-color: white;"></div>
    <div class="clr-col-lg-8 container" style="margin: 0px; padding: 0px; border: 0px;">
        <div class="clr-row" style="padding: 0px; border: 0px; margin: 0px;">
            <!-- <div class="clr-col-lg-4 clr-col-md-12 clr-col-sm-12" style="background-color: white; padding: 0px;">
                <img src="../assets/images/Email_Blue.png" id="hideimg" style="width: 100%;">
            </div> -->
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12" style="background-color: white;">
                <div class="clr-row" style="margin: 0px; padding: 0px; border: 0px;">
                    <div class="clr-col-12" style="text-align: center;">
                        <h1 class="header-text-blue">Contact Us</h1>
                    </div>
                    <div class="clr-col-12" style="height: 20px;"></div>
                    <div class="clr-col-lg-4">
                        <div class="clr-row" style="border: 0px; padding: 0px; margin: 0px;">
                            <div class="clr-col-1"><clr-icon shape="map-marker" size="25" style="color: #0d1f2b;"></clr-icon></div>
                            <div class="clr-col-11" style="font-size: 25px; color: #0d1f2b;">Address:</div>
                            <div class="clr-col-1"></div>
                            <div class="clr-col-11" style="font-size: 25px; color: #0d1f2b;">P.O. Box 451355</div>
                            <div class="clr-col-1"></div>
                            <div class="clr-col-11" style="font-size: 25px; color: #0d1f2b;">Sunrise, FL 33345</div>
                            <div class="clr-col-12" style="height: 20px;"></div>
                            <div class="clr-col-1"><clr-icon shape="phone-handset" size="25" style="color: #0d1f2b;"></clr-icon></div>
                            <div class="clr-col-11" style="font-size: 25px; color: #0d1f2b;">Phone:</div>
                            <div class="clr-col-1"></div>
                            <div class="clr-col-11" style="font-size: 25px; color: #0d1f2b;">(954) 410-8760</div>
                            <div class="clr-col-12" style="height: 50px;"></div>
                        </div>
                    </div>
                    <div class="clr-col-lg-8">
                        <div class="clr-row" style="border: 0px; padding: 0px; margin: 0px;">
                            <!-- <div class="clr-col-12" style="font-size: 25px; color: #0d1f2b;">Contact Form:</div> -->
                            <div class="clr-col-12" style="height: 15px;"></div>
                            <form clrForm [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                                <!-- <div class="clr-col-12 clr-form-control">
                                    <label for="fullName" class="clr-control-label">Full Name:</label>
                                    <div class="clr-control-container">
                                        <div class="clr-input-wrapper">
                                            <input clrInput type="text" id="fullName" name="fullName" formControlName="fullName" style="font-size: 20px;" required>
                                        </div>
                                        <span class="clr-subtext">Enter your name, upper and lower case letters only.</span>
                                    </div>
                                </div> -->
                                <clr-input-container>
                                    <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Name:</label>
                                    <input clrInput type="text" id="fullName" name="fullName" formControlName="fullName" style="font-size: 20px;" required>
                                    <clr-control-helper>Enter your name, upper and lower case letters only.</clr-control-helper>
                                    <clr-control-error>Name is required to send a message.</clr-control-error>
                                    <!-- <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12">
                                        <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Name:</label>
                                    </div>
                                    <div class="clr-col-lg-9 clr-col-md-6 clr-col-sm-12">
                                        <input clrInput class="form-input" type="text" id="fullName" name="fullName" style="font-size: 20px;">
                                    </div>
                                    <clr-control-helper>Enter your name, upper and lower case letters only.</clr-control-helper>
                                    <clr-control-error>Name is required to send a message.</clr-control-error> -->
                                </clr-input-container>
                                <clr-input-container>
                                    <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Email:</label>
                                    <input clrInput type="text" id="emailAddress" name="emailAddress" formControlName="emailAddress" style="font-size: 20px;" required>
                                    <clr-control-helper>Enter your email address.</clr-control-helper>
                                    <clr-control-error>Email is required to send a message.</clr-control-error>
                                    <!-- <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12">
                                        <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Email:</label>
                                    </div>
                                    <div class="clr-col-lg-9 clr-col-md-6 clr-col-sm-12">
                                        <input clrInput class="form-input" type="text" id="emailAddress" name="emailAddress" style="font-size: 20px;">
                                    </div>
                                    <clr-control-helper>Enter your email address.</clr-control-helper>
                                    <clr-control-error>Email is required to send a message.</clr-control-error> -->
                                </clr-input-container>
                                <clr-input-container>
                                    <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Subject:</label>
                                    <input clrInput type="text" id="emailSubject" name="emailSubject" formControlName="emailSubject" style="font-size: 20px;" required>
                                    <!-- <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12">
                                        <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Subject:</label>
                                    </div>
                                    <div class="clr-col-lg-9 clr-col-md-6 clr-col-sm-12">
                                        <input clrInput class="form-input" type="text" id="emailSubject" name="emailSubject" style="font-size: 20px;">
                                    </div> -->
                                    <clr-control-helper>Enter a message subject.</clr-control-helper>
                                    <clr-control-error>Subject is required to send a message.</clr-control-error>
                                </clr-input-container>
                                <!-- <div class="clr-col-12">
                                    <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Message:</label>
                                    <textarea formControlName="emailMsg" style="font-size: 20px; width: 300px; height: 300px;" placeholder="Enter a message" required></textarea>
                                </div> -->
                                <clr-textarea-container>
                                    <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Message:</label>
                                    <!-- <input clrInput type="text" id="emailMsg" name="emailMsg" formControlName="emailMsg" style="font-size: 20px;" required> -->
                                    <textarea clrTextarea formControlName="emailMsg" style="font-size: 20px; width: 300px; height: 150px; max-height: 100%; min-height: 45px;" placeholder="Enter a message" required></textarea>
                                    <!-- <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12">
                                        <label style="color: #0d1f2b; font-size: 20px; width: 350px; font-weight: normal;">Message:</label>
                                    </div>
                                    <div class="clr-col-lg-9 clr-col-md-6 clr-col-sm-12">
                                        <input clrInput class="form-input" type="text" id="emailMsg" name="emailMsg" style="font-size: 20px;">
                                    </div> -->
                                    <!-- <clr-control-helper>Enter a message.</clr-control-helper> -->
                                    <clr-control-error>Message is required to send an email.</clr-control-error>
                                </clr-textarea-container>

                                <!-- <div class="clr-col-12" style="height: 15px;"></div> -->
                                
                                <div class="alert alert-success" role="alert" *ngIf="sentSuccess">
                                    <div class="alert-items">
                                        <div class="alert-item static">
                                            <div class="alert-icon-wrapper">
                                                <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                                            </div>
                                            <span class="alert-text">Email sent successfully!</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="alert alert-success" role="alert" *ngIf="sentFail">
                                    <div class="alert-items">
                                        <div class="alert-item static">
                                            <div class="alert-icon-wrapper">
                                                <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                                            </div>
                                            <span class="alert-text">Email failed, please try again later!</span>
                                        </div>
                                    </div>
                                </div>  
                                
                                <div class="clr-col-12" style="height: 15px;"></div>

                                <!-- <div class="clr-col-12">
                                    <div #recaptcha ></div>
                                </div> -->

                                <!-- <div class="clr-col-12" style="height: 15px;"></div> -->
                                <clr-control-container>
                                    <!-- <div class="clr-col-12" style="position: relative;"> -->
                                        <button class="btn btn-primary" [clrLoading]="submitBtnState" type="submit">Send</button>
                                    <!-- </div> -->
                                </clr-control-container>
                            </form>
                            <div class="clr-col-12" style="height: 15px;"></div>
                        <!-- </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-2 clr-col-md-0 clr-col-sm-0" style="background-color: white;"></div>
</div>