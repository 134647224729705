<!-- <clr-header class="header header-7" style="background-color: #0d1f2b; border-bottom-width: 3px; border-bottom-style: solid; padding-right: 0px; padding-bottom: 0px; border-right: 0px; margin-right: 0px; border-bottom: 0px; margin-bottom: 0px; height: 100px;">
    <div class="branding" style="border-bottom: 0px; height: 100%; top: 0px;">
        <a routerLink="/home" class="nav-link">
            <img src="../assets/images/Logo_Banner_500px_clear_goldback.png" style="height: 110px; top: -5px; position: absolute;"/>
        </a>
    </div>
</clr-header> -->
<clr-header class="header header-6" style="background-color: #0d1f2b; border-bottom-width: 3px; border-bottom-style: solid; padding-right: 0px; padding-bottom: 0px; border-right: 0px; margin-right: 0px; border-bottom: 0px; margin-bottom: 0px; height: 100px;">
    <div class="clr-row" style="text-align: center; vertical-align: middle;">
        <div class="clr-col-12" style="text-align: center; vertical-align: middle; margin-top: 5px;">
            <img src="../assets/images/Logo_Banner_500px_clear_goldback.png" style="height: 110px; top: -5px; position: absolute;"/>
        </div>
        <!-- <div class="clr-col-12" style="height: 3px; background-color:#009bec;">
            &nbsp;
        </div> -->
        <div class="clr-col-12" style="height: 1px">&nbsp;</div>
    </div>
</clr-header>
