<div class="clr-row" style="border: 0px; margin: 0px; background-color: white;">
    <div class="clr-col-12 container" style="padding: 0px; margin: 0px; border: 0px;">
        <img src="..\assets\images\Sponsors003.jpg" style="width: 100vw; max-width: 100%;">
        <div style="position: absolute; top: 1%; left: 50%; transform: translate(-50%,0);">
            <h1 style="color: #0d1f2b; font-size: 4vw; line-height: 4vw; font-weight: bolder; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">Our Sponsors</h1>
        </div>
    </div>
    <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
    <div class="clr-col-lg-8 clr-col-md-8 clr-col-sm-12">
        <p class="text-blue" style="text-align: center;">
            FLEPRU joins with many companies and organizations to address complex, investigative challenges that our members face.  Our partners 
            contribute more than money.  Their ideas, volunteer power, support and more are helping to shape the way we investigate crimes 
            within the State of Florida.
        </p>
    </div>
    <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>

    <div class="clr-col-12" style="height: 20px;"></div>

    <div class="clr-col-12" style="text-align: center;">
        <h1 class="header-text-blue">Vendor Sponsorship Opportunities</h1>
    </div>

    <div class="clr-col-12">
        <div class="clr-row" style="display: flex; display: -webkit-flex;">
            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-6">
                <div class="sponsor-card" id="reg" style="background-color:#B9F2FF;">
                    <div class="sponsor-card-header" style="color: #0d1f2b;">Diamond</div>
                    <div class="sponsor-card-text" style="color: #0d1f2b;">
                        <ul>
                            <li>
                                Breakfast sponsor (Signage)
                            </li>
                            <li>
                                Up to four attendees
                            </li>
                            <li>
                                Podium to address at breakfast (10 minutes)
                            </li>
                            <li>
                                Intro to Assembly of Speaker
                            </li>
                            <li>
                                Company prominently displayed on websites
                            </li>
                            <li>
                                Includes six (6) foot table, chairs and logo on websites
                            </li>
                            <li>
                                Access to Network Events Conference
                            </li>
                        </ul>
                    </div>
                    <div class="sponsor-card-cost" style="color: #0d1f2b;">
                        $5000
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-6">
                <div class="sponsor-card" id="hos" style="background-color: #DAA520;">
                    <div class="sponsor-card-header" style="color: #0d1f2b;">Gold</div>
                    <div class="sponsor-card-text" style="color: #0d1f2b;">
                        <ul>
                            <li>
                                Snack sponsor signage
                            </li>
                            <li>
                                Up to three (3) attendees
                            </li>
                            <li>
                                Podium to address at snack
                            </li>
                            <li>
                                Company displayed on websites
                            </li>
                            <li>
                                Includes six (6) foot table and charis
                            </li>
                            <br>
                            <br>
                        </ul>
                    </div>
                    <div class="sponsor-card-cost" style="color: #0d1f2b;">
                        $2500
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-4 clr-col-sm-6">
                <div class="sponsor-card" id="break" style="background-color: #C0C0C0;">
                    <div class="sponsor-card-header" style="color: #0d1f2b;">Silver</div>
                    <div class="sponsor-card-text" style="color: #0d1f2b;">
                        <ul>
                            <li>
                                Up to two (2) attendees
                            </li>
                            <li>
                                Company displayed on website
                            </li>
                            <li>
                                Includes six (6) foot table and chairs
                            </li>
                            <br>
                            <br>
                            <br>
                            <br>
                        </ul>
                    </div>
                    <div class="sponsor-card-cost" style="color: #0d1f2b;">
                        $1000
                    </div>
                </div>
            </div>  
            
            <div class="clr-col-12">

                <div class="clr-col-12" style="height: 20px;"></div>

                <div style="text-align: center;">
                    <button class="button2" style="display: inline-block;" onclick="window.open('https://cvent.me/mArEe9','_blank')">Vendor Registration</button>
                </div>
                
                <div class="clr-col-12" style="height: 20px;"></div>
            </div>
                       
        </div>
    </div>

    <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12">
        <div class="clr-row" style="padding: 0px; margin: 0px; border: 0px;">
            <div class="clr-col-12" style="text-align: center;">
                <h1 class="header-text-blue">Premier Sponsors</h1>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\Business_Watch_International.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Business Watch International</h1>
                        <p>BWI combats community crime with data.  Specifically, it harvests and analyses transactional data resulting from the pawning of sale of used goods as a means of identifying criminal activities, treans and linkages, empowering law enforcement and business in the protection of communities they serve.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('http://www.bwirapid.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\rapid.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>RAPID</h1>
                        <p>In many police departments, the current reporting systems are antiquated, unmanageable, and largely unable to accomodate the 
                            growing influx of reported transactions.  As a result, the officers mandated to investigate this data are overwhelmed.  Simultaneously, 
                            due to budget constraints and cuts, police agencies are burdened with managing this data set with already overextended resources, and as 
                            such, are constantly searching for ways to be more effecient and effective.  RAPID directly addresses police officer's need for 
                            improved accuracy, quantity, relevancy and timeliness of transaction information related to pawn, scrap metal and precious metal 
                            transactions.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('http://www.bwirapid.com/index.php/products/bwi-rapid','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\verizon.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Verizon</h1>
                        <p>
                            We deliver superior customer experiences through our products and our actions.  Everything we do we build on 
                            a strong network, systems and process foundation.  The quality and reliability of the products we deliver are 
                            paramount.  Customers pay us to provide them with services that they can rely on.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.verizon.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\macys.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Macy's</h1>
                        <p>Macy's is an American department store chain founded in 1858 by Rowland Hussey Macy.  It became a division of the Cincinnati-based Federated Department Stores in 1994, through which it is affiliated with the Bloomingdale's department store chain.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.macys.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-12" style="text-align: center;">
                <h1 class="header-text-blue">Additional Partners and Sponsors</h1>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\auror.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Auror.</h1>
                        <p>
                            Auror is a software platform that makes it easy for retailers to prevent theft, share intel and crime reports 
                            directly with police, and create safer, more profitable sites for customers and teams.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.milestonesys.com/marketplace/auror-limited/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>    

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\zellman.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Zellman</h1>
                        <p>
                            The Zellman Group is the premiere comprehensive loss prevention solution, providing services such as Civil Recovery, ORC Recovery, 
                            Data Analytics, and OSINT, ORC Recovery, and more.  Our managed solutions are customizable to companies of any size or industry.  
                            Our team of experts has decades of experience in the retail, food service, and hospitality industries, and we come from diverse 
                            professional backgrounds including loss prevention, legal, operations, and e-commerce.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.zellmangroup.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>    

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\clear.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>CLEAR</h1>
                        <p>
                            The Coalition of Law Enforcement and Retail (CLEAR) is a non-profit, non-partisan national association founded in November 2008 
                            by a group of Law Enforcement and Retail Loss Prevention professionals that saw the need for the formation of a partnership. 
                            The Coalition provides a forum for public and private sector members, with experience in a wide variety of public safety initiatives, 
                            to aid and support the establishment of collaborative efforts that are focused on improving the safety of our communities and 
                            advancing the profession of its membership.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.clearusa.org/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>    

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\centerforlaw.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>The Center for Law Enforcement Technology, Training & Research</h1>
                        <p>LETTR's mission statement is "To assist public safety entities by developing low cost, user-defined software systems, applications and training programs to facilitate collaboration between Law Enforcement personnel across and within agencies.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://lettr.org/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\inpixon.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Inpixon</h1>
                        <p>
                            Inpixon's comprehensive Indoor Intelligence platform provides organizations with the tools to ingest, and integrate data with indoor 
                            maps.  With a range of dynamic, scalable solutions encompassing indoor mapping, analytics, security and indoor positioning, our 
                            technology empowers you to create impactful tailored solutions across your organization.  Start harnessing the power of your indoor 
                            data today with Inpixon.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.inpixon.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\NITV_Federal_Services.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>NITV Federal Services</h1>
                        <p>Our culture of continuous technical innovation and process improvement ensures our clients are always provided with state-of-the-art voice stress analysis instrumentation, the best training available worldwide, and unparalleled customer/technical support.  Our services and support are provided by a US-based, full-time team of professionals from our headquarters in South Florida.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.cvsa1.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\ReadyOp.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>ReadyOp</h1>
                        <p>ReadyOp provides an innovative platform for clearly defined tasks and contact structures to ensure orderly and efficient dissemniation of information.  Easily locate, assign tasks and communicate with individuals and groups in a single agency or multiple organizations.  All through an easy to use visual dashboard display.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.readyop.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\heroes-first.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Heroes First Home Loans</h1>
                        <p>Those who serve, protrect and care for our communities observe a reward for their selflessness and hard work.  
                            The Heroes First Team at Churchill Mortgage is proud to give back to these everyday heroes by providing specialty 
                            mortgage financing to minimize their cost when buying or refinancing a home.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://info.churchillmortgage.com/heroes-first','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\securitas.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>SECURITAS Electronic Security</h1>
                        <p>Securitas is the most locally-focused security company in the country.  As an industry leading security provider, Securitas 
                            focuses on ways to continuously improve our operational structure, the knowledge of our employees and the processes surrounding 
                            service delivery.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.securitasinc.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\vetted_security_solutions.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Vetted Security Solutions</h1>
                        <p>Since 2012 Vetted has been partnering with industry leading manufacturers to bring you turn-key solutions and the highest focus on customer support.  Our products include the most comprehensive, advanced suite of license plate recognition (LPR), facial recognition, analytics, commercial data, and mobile surveillance solutions available to improve safety for officers and communities.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://vettedsecuritysolutions.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\american-high-tech.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>American High-Tech Transcription And Reporting</h1>
                        <p>
                            Based in Largo, Florida, American High-Tech Transcription & Reporting, Inc. is a transcription and translation agency.  
                            As a certified, woman-owned small business, we provide accurate, secure services for government agencies and companies 
                            worldwide.  We work with national and international companies, although all translastion and transcription services are 
                            performed in the United States.  Our company has been operating in Pinellas County, Florida since 1994.  Prior to that, 
                            we operated as Segal Reporting Service.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.w-z.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\aactioninvestigator.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>A Action Investigator</h1>
                        <p>We are a full service corporate investigative agency.  We specialize in intellectual property cases as well as General Investigations.  What sets us apart from other agencies is our experience and dedication to excel in the accomplishments of our work.  We have full time college educated licensed investigators in our offices.  We use high tech state of the art equipment to successfully complete all of our cases.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('http://www.aactioninv.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\safer-watch.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Safer Watch</h1>
                        <p>
                            SaferWatch is a web and mobile based security system that was built to provide a higher level of safety for individuals and 
                            enhance security for organizations including schools, corporations, public venues, neighborhoods and communities.  SaferWatch 
                            empowers users to report an incident as they see it happening or submit a tip at a later time.  SaferWatch provides real-time 
                            two-way communication during emergency and non-emergency situations.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.saferwatchapp.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\skimreaper.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Skim Reaper</h1>
                        <p>
                            Skim Reaper specifically targets overlay and deep-insert skimmers, quickly letting you know if the terminal has a skimmer.  
                            It was designed with award-winning academic research, including a comprehensive breakdown of real skimmers.  We have shipped 
                            prototype devices around the United States and these have successfully identified skimming devices in the field.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.skimreaper.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\cellebrite.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Cellebrite</h1>
                        <p>
                            Cellebrite enables investigators to capture insights in today's complex, digital world.  Our digital intelligence platform 
                            provides a complete and objective picture of evidence, empowering agencies and investigators to solve and close cases faster 
                            than ever.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.cellebrite.com/en/home/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\gost.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Global Ocean Security Technologies</h1>
                        <p>
                            GOST offers a full line of state-of-the-art wireless security, monitoring, satellite tracking, surveillance, acoustic deterrents and 
                            cloaking systems for any size vessel from tenders to ships.  Our systems help protect against theft, fire, smoke, high water in the 
                            bilge, low voltage, loss of shore power and intrusion with contact sensors, motion detectors, infrared beam sensors, deck sensors, 
                            dock pull away sensors and many more.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://gostglobal.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>



            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\first-responders-financial.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>First Responders Financial</h1>
                        <p>
                            After working with Municipal and Florida Retirement System members for several years, First Responders Financial realized that 
                            there were many questions and few ways to get the right answers, so First Responders Financial to provide resources.  They can 
                            help you with your planning needs including Life Insurance, Long Term Care Insurance, Retirement Planning (Roth, Traditional 
                            and Rollover IRA's, Fixed and Variable Annuities and Managed Accounts), and understanding your Municipal and FRS benefits.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://firstrespondersfinancial.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\fdsa.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Florida Deputy Sheriff's Association</h1>
                        <p>
                            The mission of the Florida Deputy Sheriff's Association is to establish a network of knowledge, cohesiveness and security for 
                            law-enforcement officers, correction officers and employees of Florida Sheriffs and to promote professionalism.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.fldeputysheriffs.org/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\iafci.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>International Association of Financial Crimes Investigators</h1>
                        <p>
                            The IAFCI, a non-profit international organization, will provide services and an environment within which information about 
                            Financial fraud, fraud investigation and fraud prevention methods can be collected, exchanged and taught for the common good 
                            of The financial payment industry and our global society.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.iafci.org/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12">
        <div class="clr-row" style="margin: 0px; padding: 0px; border: 0px;">
            <div class="clr-col-12" style="text-align: center;">
                <h1 class="header-text-blue">Retail Sponsors</h1>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\verizon.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Verizon</h1>
                        <p>
                            We deliver superior customer experiences through our products and our actions.  Everything we do we build on 
                            a strong network, systems and process foundation.  The quality and reliability of the products we deliver are 
                            paramount.  Customers pay us to provide them with services that they can rely on.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.verizon.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\macys.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Macy's</h1>
                        <p>Macy's is an American department store chain founded in 1858 by Rowland Hussey Macy.  It became a division of the Cincinnati-based Federated Department Stores in 1994, through which it is affiliated with the Bloomingdale's department store chain.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.macys.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\CVS_FLEPRU_NEW.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>CVS</h1>
                        <p>CVS Pharmacy is an American retail corporation Owned by CVS Health.  It is headquartered in Woonsocket, Rhode Island.  It is also known as, and originally named, the Consumer Value Store and was founded in Lowell, Massachusetts in 1963.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.cvs.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\HomeDepot_FLEPRU.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>The HomeDepot</h1>
                        <p>The Home Depot, Inc. is the largest home improvement retailer in the United States, supplying tools, construction products, and services.  The company is headquartered in incorporated Cobb County, Georgia.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.homedepot.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\TJX_Logo.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>TJX Companies</h1>
                        <p>For us, value is a combination of brand, fashion, price, and quality.  Unlike more traditional retailers, our retail chains generally don't engage in promotional pricing activity like sales, coupons, or other gimmicks - just exciting merchandise at amazing prices, every single day!</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.tjx.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\target.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Target</h1>
                        <p>First opened in Roseville, Minnesota on May 1, 1962, Target is the 8th largest retailer in the United States.  Today, the retail giant has just shy of 2000 stores nationwide.</p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.target.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\lowes.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Lowe's</h1>
                        <p>
                            Lowe's grew from one small-town hardware store in North Carolina to on of the largest home improvement retailers 
                            in the world.  Then and now, we're committed to helping homeowners, renters and pros improve their homes and businesses.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.lowes.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\american-eagle.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>American Eagle Outfitters</h1>
                        <p>
                            American Eagle Outfitters (NYSE: AEO) is a leading global specialty retailer offering high-quality. on-trend clothing, 
                            accessories and personal care products at affordable prices under its American Eagle and Aerie brands.  The company operates 
                            more than 1,000 stores in the United States, Canada, Mexico, and hong Kong, and ships to 81 countries worldwide through ins 
                            websites.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.ae.com/us/en','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>


            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\at&t.png" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>AT&T</h1>
                        <p>
                            As the first truly modern media company, AT&T has been changing the way people live, work and play for the past 144 years.  It 
                            started with Alexander Graham Bell's telephone.  Since then, our legacy of innovation has included the invention of the transistor 
                            - the building block of today's digital world - as well as the solar cell, the communications satellite and machine learning.
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.att.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                <div class="clr-row inner-row">
                    <div class="clr-col-4 logo-div">
                        <img src="..\assets\images\ross.jpg" style="width: 80%;">
                    </div>  
                    <div class="clr-col-7 info-div">
                        <h1>Ross Dress for Less</h1>
                        <p>
                            Since 1982, our focus has been on bringing our customers a constant stream of high quality department and specialty store brands 
                            at extraordinary savings a.k.a. bargains, while providing an easy, fun and organized shopping experience.  
                        </p>
                    </div>
                    <div class="clr-col-1 arrow-div" onclick="window.open('https://www.rossstores.com/','_blank')">
                        <img src="..\assets\images\right_triangle.png">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
    <div class="clr-col-lg-8 clr-col-md-8 clr-col-sm-12">
        <div style="padding: 10px;">
            <div class="card" style="border: 0px;">
            <div class="card-header" style="text-align: left; vertical-align: bottom; line-height: 20px;">
                <h1 style="line-height: 20px;" class="header-text-blue"><img src="..\assets\images\Emblem_blue.png" style="width: 40px;">Our Sponsors</h1>
            </div>
            <div class="card-block">
                <div class="card-text">
                    <div class="clr-row" style="padding: 10px; text-align: center;">
                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\LPRC-LOGO.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Loss Prevention Research Council</h1>
                                    <p>The LPRC strives to provide comprehensive research, development opportunities, and collaborative spaces for our members that will enable the innovation of loss and crime control solutions.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://lpresearch.org/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\TJX_Logo.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>TJX Companies</h1>
                                    <p>For us, value is a combination of brand, fashion, price, and quality.  Unlike more traditional retailers, our retail chains generally don't engage in promotional pricing activity like sales, coupons, or other gimmicks - just exciting merchandise at amazing prices, every single day!</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.tjx.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\uf.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>University of Florida<br>
                                        Computer & Information<br>
                                        Science & Engineering</h1>
                                    <p>The mission of the Department of Computer & Information Science & Engineering is to educdate students, as well as the broader campus community, in the fundamental concepts of the computing discipline; to create and disseminate computing knowledge and technology; and to use expertise in computing to help society solve problems.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.cise.ufl.edu/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\target.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Target</h1>
                                    <p>First opened in Roseville, Minnesota on May 1, 1962, Target is the 8th largest retailer in the United States.  Today, the retail giant has just shy of 2000 stores nationwide.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.target.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\ReadyOp.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>ReadyOp</h1>
                                    <p>ReadyOp provides an innovative platform for clearly defined tasks and contact structures to ensure orderly and efficient dissemniation of information.  Easily locate, assign tasks and communicate with individuals and groups in a single agency or multiple organizations.  All through an easy to use visual dashboard display.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.readyop.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\vetted_security_solutions.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Vetted Security Solutions</h1>
                                    <p>Since 2012 Vetted has been partnering with industry leading manufacturers to bring you turn-key solutions and the highest focus on customer support.  Our products include the most comprehensive, advanced suite of license plate recognition (LPR), facial recognition, analytics, commercial data, and mobile surveillance solutions available to improve safety for officers and communities.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://vettedsecuritysolutions.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\sprint.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Sprint</h1>
                                    <p>Sprint Corporation was the fourth-largest mobile network operator in the United Stats.  It was acquired by T-Mobile on April 1, 2020.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.sprint.com','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\NITV_Federal_Services.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>NITV Federal Services</h1>
                                    <p>Our culture of continuous technical innovation and process improvement ensures our clients are always provided with state-of-the-art voice stress analysis instrumentation, the best training available worldwide, and unparalleled customer/technical support.  Our services and support are provided by a US-based, full-time team of professionals from our headquarters in South Florida.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.cvsa1.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\Wicklander_Zulawski.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Wicklander Zulawski</h1>
                                    <p>Wicklander-Zulawski & Associates is a consulting and training organization dedicated to supporting professionals in the difficult task of identifying the truth.  Our passision for the truth has led us to become a world leader in non-confrontational interview and interrogation training.  Over 450 programs are facilitated annually for clients in human resources, loss prevention, executive management, compliance, law enforcement and government agencies.  Each program is specifically designed with the individual client to ensure maximum application value.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.w-z.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\VideoNet.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>VideoNet</h1>
                                    <p>VideoNet Holdings Corp. was founded on one goal: bringing people together.  We believe that all businesses should be able to serve their employees, clients, and customers using modern communications tools like videoconferencing, live streaming, real-time screen sharing, and the like.  Our team brings together decades of experience in the WebRTC industory, providing effecient, affordable, and user-friendly video conferencing solutions to businesses across various industries.  We pride ourselves in creating digital solutions that allow people to work and communicate in a way that boosts their productivity while saving them valuable time, effort, and money.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://videonet.us/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\rapid.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Rapid</h1>
                                    <p>In many police departments, the current reporting systems are antiquated, unmanageable, and largely unable to accomodate the growing influx of reported transactions.  RAPID directly addresses police officer's need for improved accuracy, quantity, relevancy and timeliness of transaction information related to pawn, scrap metal and precious metal transactions.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('http://www.bwirapid.com/index.php/products/bwi-rapid','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\macys.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Macy's</h1>
                                    <p>Macy's is an American department store chain founded in 1858 by Rowland Hussey Macy.  It became a division of the Cincinnati-based Federated Department Stores in 1994, through which it is affiliated with the Bloomingdale's department store chain.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.macys.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\zellman.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Zellman</h1>
                                    <p>The Zellman team has the pulse on industry-wide best practices to provide you with the individually configured service(s) you choose!  Our core Loss Prevention services radiate from this knowledge base: Analytics, Civil Recovery, Organized Retail Crime, and Operations.  Proven solutions that provide measurable restuls.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.zellmangroup.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\centerforlaw.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>The Center for Law Enforcement Technology, Training & Research</h1>
                                    <p>LETTR's mission statement is "To assist public safety entities by developing low cost, user-defined software systems, applications and training programs to facilitate collaboration between Law Enforcement personnel across and within agencies.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://lettr.org/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\CVS_FLEPRU_NEW.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>CVS</h1>
                                    <p>CVS Pharmacy is an American retail corporation Owned by CVS Health.  It is headquartered in Woonsocket, Rhode Island.  It is also known as, and originally named, the Consumer Value Store and was founded in Lowell, Massachusetts in 1963.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.cvs.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\HomeDepot_FLEPRU.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>The HomeDepot</h1>
                                    <p>The Home Depot, Inc. is the largest home improvement retailer in the United States, supplying tools, construction products, and services.  The company is headquartered in incorporated Cobb County, Georgia.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.homedepot.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\clear.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Clear</h1>
                                    <p>CLEAR's mission is to build and support public-private sector partnerships focused on improving the safety of our communities.  We accomplish this mission through supporting collaborative training and education events, open communication and networking opportunities.  By working and training together, our Members create a tenor in which all understand they are stakeholders, who are aware and vested in the security and protection of our communities.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('https://www.clearusa.org/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\Business_Watch_International.jpg" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>Business Watch International</h1>
                                    <p>BWI combats community crime with data.  Specifically, it harvests and analyses transactional data resulting from the pawning of sale of used goods as a means of identifying criminal activities, treans and linkages, empowering law enforcement and business in the protection of communities they serve.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('http://www.bwirapid.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>

                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 outer-col">
                            <div class="clr-row inner-row">
                                <div class="clr-col-4 logo-div">
                                    <img src="..\assets\images\aactioninvestigator.png" style="width: 80%;">
                                </div>  
                                <div class="clr-col-6 info-div">
                                    <h1>A Action Investigator</h1>
                                    <p>We are a full service corporate investigative agency.  We specialize in intellectual property cases as well as General Investigations.  What sets us apart from other agencies is our experience and dedication to excel in the accomplishments of our work.  We have full time college educated licensed investigators in our offices.  We use high tech state of the art equipment to successfully complete all of our cases.</p>
                                </div>
                                <div class="clr-col-1 arrow-div" onclick="window.open('http://www.aactioninv.com/','_blank')">
                                    <img src="..\assets\images\right_triangle.png">
                                </div>
                            </div>
                        </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div> -->
    <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
</div>
