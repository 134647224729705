<div class="clr-row" style="margin: 0px; padding: 0px; border: 0px; background-color: white;">
    <div class="clr-col-lg-2 clr-col-md-12 clr-col-sm-12"></div>
    <div class="clr-col-lg-8 clr-col-md-12 clr-col-sm-12">
        <div class="clr-row" style="border: 0px; padding: 0px; margin: 0px; text-align: center; display: -webkit-flex; display: flex;">
            <div class="clr-col-12" style="text-align: center;">
                <h1 class="header-text-blue">Board of Directors</h1>
            </div>
            <div class="clr-col-12" style="height: 20px;">&nbsp;</div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\Rich_Rossman_300x300.jpg">
                        </div>  
                        <div class="board-title">
                            President
                        </div>
                        <div class="board-name">
                            Rich Rossman
                        </div>
                        <div class="board-agency">
                            Broward Sheriff's Office
                        </div>
                        <div class="board-agency">
                            (954) 410-8760
                        </div>    
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\Dianne_Wantuck_200x300.jpg">
                        </div>  
                        <div class="board-title">
                            Vice President
                        </div>
                        <div class="board-name">
                            Diane Wantuck
                        </div>
                        <div class="board-agency">
                            Coral Springs Police Department
                        </div>
                        <div class="board-agency">
                            (954) 829-4541
                        </div>    
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\nancy_alvarez.jpg">
                        </div>  
                        <div class="board-title">
                            Vice President Training
                        </div>
                        <div class="board-name">
                            Nancy Alvarez
                        </div>
                        <div class="board-agency">
                            Monroe County Sheriff's Office
                        </div>
                        <div class="board-agency">
                            (305) 481-8060
                        </div>        
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\michael_bulzone_300x300.jpg">
                        </div>  
                        <div class="board-title">
                            Treasurer
                        </div>
                        <div class="board-name">
                            Michael Bulzone
                        </div>
                        <div class="board-agency">
                            Sunrise Police Department
                        </div>
                        <div class="board-agency">
                            (954) 821-2382
                        </div>        
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\amy-bio_cropped.jpg">
                        </div>  
                        <div class="board-title">
                            Secretary
                        </div>
                        <div class="board-name">
                            Amy Spiehs-Hicks
                        </div>
                        <div class="board-agency">
                            CVS
                        </div>
                        <div class="board-agency">
                            (813) 422-4014
                        </div>        
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12">
                <div class="clr-row outer-row" style="max-width: 300px;">
                    <div class="clr-col-12 outer-box">
                        <!-- <div class="board-img">
                            <img class="img-circle" src="..\assets\images\mitzi_02.jpg">
                        </div>   -->
                        <div class="fill-test">
                            <img class="img-test" src="..\assets\images\mitzi_02.jpg">
                        </div>  
                        <div class="board-title">
                            Advisor (Past President)
                        </div>
                        <div class="board-name">
                            Mitzi Perry
                        </div>
                        <div class="board-agency">
                            Volusia County Sheriff's Office
                        </div>
                        <div class="board-agency">
                            (386) 822-5712
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\Dane_Waugh_300x300.png">
                        </div>  
                        <div class="board-title">
                            State Director
                        </div>
                        <div class="board-name">
                            Dane Waugh
                        </div>
                        <div class="board-agency">
                            Amazon - Investigations Manager
                        </div>
                        <div class="board-agency">
                            (904) 885-8547
                        </div>        
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\scott-frantz_300x300.jpg">
                        </div>  
                        <div class="board-title">
                            District Director
                        </div>
                        <div class="board-name">
                            Scott Frantz
                        </div>
                        <div class="board-agency">
                            Retired Daytona Beach Police Department
                        </div>
                        <div class="board-agency">
                            (386) 527-0978
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\Mike_Lamia_bio_300x300.jpg">
                        </div>  
                        <div class="board-title">
                            District Director
                        </div>
                        <div class="board-name">
                            Mike Lamia
                        </div>
                        <div class="board-agency">
                            Boca Raton Police Services Department
                        </div>
                        <div class="board-agency">
                            (561) 990-8625
                        </div>        
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\male_silhouette.jpg">
                        </div>  
                        <div class="board-title">
                            District Director
                        </div>
                        <div class="board-name">
                            Vacant Position
                        </div>
                        <div class="board-agency">
                            &nbsp;
                        </div>
                        <div class="board-agency">
                            &nbsp;
                        </div>
        
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                <div class="clr-row outer-row">
                    <div class="clr-col-12 outer-box">
                        <div class="board-img">
                            <img class="img-circle" src="..\assets\images\male_silhouette.jpg">
                        </div>  
                        <div class="board-title">
                            District Director
                        </div>
                        <div class="board-name">
                            Vacant Position
                        </div>
                        <div class="board-agency">
                            &nbsp;
                        </div>
                        <div class="board-agency">
                            &nbsp;
                        </div>
        
                    </div>
                </div>
            </div>

        </div>
    </div>
  
    
    
    
    
    
    <!-- <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
    <div class="clr-col-lg-8 clr-col-md-8 clr-col-sm-12 container" style="margin: 0px; padding: 0px; border: 0px;">
        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12">
            <div style="padding: 10px;">
                <div class="card" style="border: 0px;">
                <div class="card-header" style="font-size: 40px; text-align: left; vertical-align: bottom; line-height: 40px;">
                    <h1 style="line-height: 20px;"><img src="..\assets\images\Emblem_blue.png" style="width: 40px;">Board of Directors</h1>
                </div>
                <div class="card-block">
                    <div class="card-text">
                        <div class="clr-row">
                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\rich-rossman-bio.jpg">
                                </div>  
                                <div style="font-size: 25px;">
                                    President
                                </div>
                                <div style="font-size: 15px;">
                                    Rich Rossman
                                </div>
                                <div style="font-size: 15px;">
                                    Broward Sheriff's Office
                                </div>
                                <div style="font-size: 15px;">
                                    (954) 410-8760
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\Greg_Pollock.png">
                                </div>  
                                <div style="font-size: 25px;">
                                    Vice President
                                </div>
                                <div style="font-size: 15px;">
                                    Greg Pollock
                                </div>
                                <div style="font-size: 15px;">
                                    Hillsborough County Sheriff's Office
                                </div>
                                <div style="font-size: 15px;">
                                    (813) 363-8493
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\nancy-alvarez-bio.jpg">
                                </div>  
                                <div style="font-size: 25px;">
                                    Vice President Training
                                </div>
                                <div style="font-size: 15px;">
                                    Nancy Alvarez
                                </div>
                                <div style="font-size: 15px;">
                                    Monroe County Sheriff's Office
                                </div>
                                <div style="font-size: 15px;">
                                    (305) 481-8060
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\michael_bulzone.jpg">
                                </div>  
                                <div style="font-size: 25px;">
                                    Treasurer
                                </div>
                                <div style="font-size: 15px;">
                                    Michael Bulzone
                                </div>
                                <div style="font-size: 15px;">
                                    Sunrise Police Department
                                </div>
                                <div style="font-size: 15px;">
                                    (954) 821-2382
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\amy-bio.jpg">
                                </div>  
                                <div style="font-size: 25px;">
                                    Secretary
                                </div>
                                <div style="font-size: 15px;">
                                    Amy Spiehs-Hicks
                                </div>
                                <div style="font-size: 15px;">
                                    CVS
                                </div>
                                <div style="font-size: 15px;">
                                    (813) 422-4014
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\Mitzi_Perry.png">
                                </div>  
                                <div style="font-size: 25px;">
                                    Advisor (Past President)
                                </div>
                                <div style="font-size: 15px;">
                                    Mitzi Perry
                                </div>
                                <div style="font-size: 15px;">
                                    St. Petersburg Police Department
                                </div>
                                <div style="font-size: 15px;">
                                    (727) 433-6993
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\Jason_Davies.png">
                                </div>  
                                <div style="font-size: 25px;">
                                    State Directory
                                </div>
                                <div style="font-size: 15px;">
                                    Jason Davies
                                </div>
                                <div style="font-size: 15px;">
                                    Director of ORC & Central Investigations Macy's, Inc.
                                </div>
                                <div style="font-size: 15px;">
                                    (561) 248-3481
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\scott-frantz-bio.jpg">
                                </div>  
                                <div style="font-size: 25px;">
                                    District Director
                                </div>
                                <div style="font-size: 15px;">
                                    Scott Frantz
                                </div>
                                <div style="font-size: 15px;">
                                    Daytona Beach Police Department
                                </div>
                                <div style="font-size: 15px;">
                                    (386) 527-0978
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\Evan_Connell.png">
                                </div>  
                                <div style="font-size: 25px;">
                                    District Director
                                </div>
                                <div style="font-size: 15px;">
                                    Evan Connell
                                </div>
                                <div style="font-size: 15px;">
                                    Lee County Sheriff's Office
                                </div>
                                <div style="font-size: 15px;">
                                    (239) 849-6185
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\William_Sullivan.png">
                                </div>  
                                <div style="font-size: 25px;">
                                    District Director
                                </div>
                                <div style="font-size: 15px;">
                                    William Sullivan
                                </div>
                                <div style="font-size: 15px;">
                                    Florida Department of Agriculture
                                </div>
                                <div style="font-size: 15px;">
                                    (850) 528-1906
                                </div>
                            </div>

                            <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-12" style="text-align: center;">
                                <div>
                                    <img class="img-circle" src="..\assets\images\dane-bio.jpg">
                                </div>  
                                <div style="font-size: 25px;">
                                    District Director
                                </div>
                                <div style="font-size: 15px;">
                                    Dane Waugh
                                </div>
                                <div style="font-size: 15px;">
                                    Target
                                </div>
                                <div style="font-size: 15px;">
                                    (904) 885-8547
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="clr-col-lg-2 clr-col-md-2 clr-col-sm-12"></div>
</div>
