<div class="clr-row" style="margin: 0px; padding: 0px; border: 0px; min-height: calc(100vh - 260px); background-color: white;">
    <div class="clr-col-lg-2 clr-col-md-0 clr-col-sm-0" style="background-color: white;"></div>
    <div class="clr-col-lg-8 container" style="margin: 0px; padding: 0px; border: 0px;">
        <div class="clr-row" style="padding: 0px; border: 0px; margin: 0px;">
            <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12" style="background-color: white;">
                <div class="clr-row" style="margin: 0px; padding: 0px; border: 0px;">
                    <div class="clr-col-12" style="text-align: center;">
                        <h1 class="header-text-blue">Retail Fusion Center</h1>
                    </div>
                    <div class="clr-col-12" style="height: 20px;"></div>
                    <div class="clr-col-12 subtext-blue" style="text-align: center;">
                        <span class="text-blue">Exclusive access and visibility for Florida Retail Investigators and Loss Prevention Managers</span>
                    </div>
                    <div class="clr-col-12" style="height: 20px;"></div>
                    <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="padding-top: 30px;">
                        <img src="../assets/images/fusion001.jpg" style="width: 100%; border-radius: 10px;">
                    </div>
                    <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="padding-top: 0px; margin-top: 0px; border-top: 0px;">
                        <span class="text-blue">
                            <p class="text-blue">
                                The annual FLEPRU Training is the largest public/private event of it's kind in the state of Florida and one of the fastest growing 
                                conferences in the country.
                            </p>
                            <p class="text-blue">
                                <b>Retail Fusion Center fee of $1000 Includes:</b>
                            </p>
                            <ul style="list-style-position:outside; padding-left: 30px;">
                                <li>
                                    Six (6) conference registrations
                                </li>
                                <li>
                                    Premium booth in Retail Fusion Center to include six (6) foot table, chairs and company signage
                                </li>
                                <li>
                                    Upgraded and Unrestricted access to all Special Networking Events
                                </li>
                                <li>
                                    Direct access to Property and Financial Crimes detectives from across the state and country
                                </li>
                                <li>
                                    Train alongside law enforcement with subject matter experts related to law enforcement and retail topics
                                </li>
                                <li>
                                    Private conference room for team meetings, case discussions and intelligence sharing
                                </li>
                                <li>
                                    Logo on FLEPRU and CLEAR websites
                                </li>
                            </ul>
                        </span>

                    </div>

        

                    <div class="clr-col-12" style="height: 20px;"></div>
                    <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="padding-top: 0px; margin-top: 0px; border-top: 0px;">
                        <p class="text-blue">
                            If you would like to register for this event, follow the link below:
                        </p>
                        <p style="font-size: 20px; text-align: center;">
                            <button class="button2" style="display: inline-block;" onclick="window.open('https://cvent.me/BQyWAa','_blank')">Fusion Center Registration</button>
                        </p>
                        <p class="text-blue">
                            For questions or inquiries, you can email: Registration@flepru.org
                        </p>
                        <p class="text-blue">
                            For more information on this unique training event and the Retail Fusion Center, contact:
                        </p>
                        <p class="text-blue">
                            Patrick Fairley<br>
                            (954) 495-7539<br>
                            Patrick_Fairley@homedepot.com
                        </p>
                        <p class="text-blue">
                            Terry Mullen<br>
                            (786) 412-7687<br>
                            Terrence.Mullen@cvshealth.com
                        </p>
                    </div>
                    <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12">
                        <img src="../assets/images/fusion002.jpg" style="width: 100%; border-radius: 10px;">
                    </div>
                    <div class="clr-col-12" style="height: 20px;"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-2 clr-col-md-0 clr-col-sm-0" style="background-color: white;"></div>
</div>