<div class="clr-row" style="margin: 0px; padding: 0px; border: 0px; background-color: white;">
    <div class="clr-col-12">
      <div class="clr-row" style="max-height: 90vh; overflow: hidden;">
        <section>
          <div class="clr-col-lg-12 container" style="margin: 0px; padding: 0px; border: 0px;">
            <div class="icon-scroll" style="top: 50%; left: 50px; transform: translate(-50%,0);"></div>
            <img src="..\assets\images\rosen_plaza_trimmed.jpg" style="width: 100vw; max-width: 100%;">
            <div style="position: absolute; top: 4%; right: 29%;">
              <h1 style="color: white; font-size: 4vw; line-height: 4vw; font-weight: bolder; opacity: 80%;">2022 Annual FLEPRU Conference</h1>
            </div>
            <div style="position: absolute; top: 13%; right: 29%;">
                <h1 style="color: white; font-size: 3vw; line-height: 3vw; font-weight: bolder; opacity: 80%;">November 8 - 10, 2022</h1>
            </div>
          </div>  
        </section>
      </div>
    </div>

    <div class="clr-col-12" style="margin: 0px; padding: 0px; border: 0px; background-color: #FFFFFF;">
      <div class="clr-row" style="padding: 0px; margin: 0px; border: 0px;">
        <div class="clr-col-12" style="height: 20px;"></div>
        <div class="clr-col-12" style="text-align: justify;">
          <div class="clr-row" style="border: 0%; margin: 0%; padding: 0%;">
            <div class="clr-col-12">
              <p class="event-text">
                The FLEPRU conference is a Florida based training conference made up of statewide law enforcement as well as retailers and industry 
                professionals from around the nation.  The conference includes specialized training Sessions primarily focused around property crimes, 
                trends, investigative tactics, and legislative topics.  These sessions are hosted by speakers who are experts in their field or industry 
                professionals both locally and from around the nation.  The conference will also include a Vendor Business Center and Retail Fusion 
                Center to support networking opportunities as well as give you the ability to share or view cutting edge products that can help 
                your business or agency.
              </p>
              <p class="event-text" style="text-align: center; font-weight: bold;">
                Attendee conference fee: $199<br>
                Fee waived for immediate supervisor with two (2) paid attendees
              </p>
            </div>
            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12">
              <span>
                <p class="event-text">This year's conference will be <b>November 8 - 10, 2022</b>.</p>
                <p class="event-text">
                  Rosen Plaza Hotel<br>
                  9700 International Drive<br>
                  Orlando, FL 32819
                </p><br>
              </span>
              <button class="button2" style="display: inline-block;" onclick="window.open('https://be.synxis.com/?Hotel=69868&Chain=10237&arrive=11/11/2021&depart=11/20/2021&adult=1&child=0&group=GRPFLEPRU2021','_blank')">Reserve Hotel</button>&nbsp;&nbsp;&nbsp;&nbsp;
              <button class="button2" style="display: inline-block;" onclick="window.open('https://cvent.me/PWy5zL?rt=an0Xsmp3Gk2T2iwb6HHD2g','_blank')">Attendee Registration</button>
            </div>
            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="text-align: center;">
              <p>
                <button class="button2" style="display: inline-block;" onclick="window.open('http://fleprudocs.s3-website.us-east-2.amazonaws.com/2021_Conference_Save_the_Date.pdf','_blank')">2021 Conference Save the Date</button>
              </p>
              <p>
                <button class="button2" style="display: inline-block;" onclick="window.open('http://fleprudocs.s3-website.us-east-2.amazonaws.com/2021_Conference_Agenda.pdf','_blank')">2021 Conference Agenda</button>
              </p>
            </div>
            <div class="clr-col-12" style="text-align: center;">
              <p class="event-text">Check out this article that highlights previous conferences.</p><br>
              <button class="button2" style="display: inline-block;" onclick="window.open('http://www.d-ddaily.com/2ndpage/11-8-18_FLEPRU-recapF.htm','_blank')">Article</button>
          </div>
          </div>
        </div>
        <div class="clr-col-12" style="height: 20px;"></div>
      </div>
    </div>
    
    <div class="clr-col-12" style="margin: 0px; padding: 0px; border: 0px; background-color: white;">
        <div class="clr-row" style="padding: 0px; margin: 0px; border: 0px;">
          <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="margin: 0px; padding: 10px; border: 0px;">
            <div class="clr-row">
              <div class="clr-col-12">
                <img src="..\assets\images\pointe_orlando.jpg" style="width: 100%;">
              </div>
            </div>
          </div>
          <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="margin: 0px; padding: 10px; border: 0px; text-align: center;">
            <span class="text-blue">
              <h1 style="font-weight: bold;">Featured Presentations</h1>
              <ul style="list-style-type: none;">
                <li>Social Meida and Open Source Investigations: Presented by Michele Stuart</li>
                <li>Real-Time Crime Center: Presented by NYPD Inspector Janice Holmes</li>
                <li>Interview and Interrogations: Presented by L. Wayne Hoover, CFI Senior Partner Wicklander-Zulawski (WZ)</li>
              </ul>
            </span>
  
            <span class="text-blue">
              <h3 style="font-weight: bold;">Other Topics Include</h3>
              <ul style="list-style-type: none; font-size: 15px;">
                <li>Search Warrants and Court Orders</li>
                <li>E-Commerce Investigations</li>
                <li>Building an ORC Task Force</li>
                <li>Burglary and Robbery Investigations Best Practices</li>
                <li>FBI Major Theft Task Force</li>
                <li>Leadership Training from a Law Enforcement Perspective</li>
              </ul>
            </span>
            
            <div class="clr-row" style="padding: 0%; margin: 0%; border: 0%;">
              <div class="clr-col-12" style="height: 20px;"></div>
              <div class="clr-col-6">
              </div>
              <div class="clr-col-6">
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="clr-col-12" style="margin: 0px; padding: 0px; border: 0px; background-color: #0d1f2b;">
      <div class="clr-row" style="padding: 0px; margin: 0px; border: 0px;">
        <div class="clr-col-12" style="height: 20px;"></div>
        <div class="clr-col-12" style="height: 500px; text-align: center;">
          <div class="clr-row" style="padding: 0px; border: 0px; margin: 0px; height: 100%;">
            <div class="clr-col-lg-2 clr-col-md-12 clr-col-sm-12"></div>
            <div class="carousel clr-col-lg-8 clr-col-md-12 clr-col-sm-12" style="text-align: center;">
              <ng-container *ngFor="let photo of photoArray; let i = index" style="height: 100%; width: 100%;">
                <img *ngIf="i === currentSlide" [src]="photo.src" class="slide" style="height: 500px; width: auto; position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);" @carouselAnimation>
              </ng-container>  
              <button class="control control-prev" (click)="onPreviousClick()">
                <span><img src="../assets/images/left-arrow.png"></span>
              </button>
              <button class="control control-next" (click)="onNextClick()">
                <span><img src="../assets/images/right-arrow.png"></span>
              </button>
            </div>
            <div class="clr-col-lg-2 clr-col-md-12 clr-col-sm-12"></div>
          </div>
        </div>
        <div class="clr-col-12" style="height: 20px;"></div>
      </div>
    </div>
    
</div>