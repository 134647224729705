import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailServiceService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private httpClient: HttpClient) { }

  sendEmail(emailObj): Observable<any> {
    return this.httpClient.post<any>('https://sm1dt5okrf.execute-api.us-east-1.amazonaws.com/flepruEmailStage/emailer-resource',emailObj)
    //return this.httpClient.post('https://sm1dt5okrf.execute-api.us-east-1.amazonaws.com/flepruEmailStage',emailObj)
  }
}
