<div class="clr-row" style="margin: 0px; padding: 0px; border: 0px; background-color: white;">
  <div class="clr-col-12">
    <div class="clr-row" style="max-height: 90vh;">
      <section id="section1">
        <div class="clr-col-lg-12 container" style="margin: 0px; padding: 0px; border: 0px;">
            <!-- <div class="icon-scroll" style="top: 50%; left: 50px; transform: translate(-50%,0);"></div> -->
              <img src="..\assets\images\banner__short.png" style="width: 100vw; max-width: 100%;">
              <ul id="grid" class="clear" style="position: absolute; top: 2%; left: 2%;">
                <li style="opacity: 80%;">
                  <div class="hexagon" style="text-align: center;">
                    <div class="textdiv">
                      <h1 style="font-size: 2vw; font-weight: bolder; line-height: 2vw;">Burglary</h1>
                    </div>
                  </div>
                </li>
                <li style="opacity: 80%;">
                  <div class="hexagon">
                    <div class="textdiv">
                      <h1 style="font-size: 2vw; font-weight: bolder; line-height: 2vw;">Auto Theft</h1>
                    </div>
                  </div>
                </li>
                <li style="opacity: 80%;">
                  <div class="hexagon">
                    <div class="textdiv">
                      <h1 style="font-size: 2vw; font-weight: bolder; line-height: 2vw;">Organized Retail Crime</h1>
                    </div>
                  </div>
                </li>
                <li style="opacity: 80%;">
                  <div class="hexagon">
                    <div class="textdiv">
                      <h1 style="font-size: 2vw; font-weight: bolder; line-height: 2vw;">Robbery</h1>
                    </div>
                  </div>
                </li>
                <li style="opacity: 80%;">
                  <div class="hexagon">
                    <div class="textdiv">
                      <h1 style="font-size: 2vw; font-weight: bolder; line-height: 2vw;">Fraud</h1>
                    </div>
                  </div>
                </li>
              </ul> 
              <div style="position: absolute; top: 15%; left: 40%;">
                <h1 style="color: white; font-size: 3.5vw; line-height: 4vw; font-weight: bolder; opacity: 80%;">REDEFINING PROPERTY CRIME INVESTIGATIONS SINCE 1987</h1>
              </div>
              <div style="position: absolute; top: 55%; left: 40%;">
                <a class="button1" href="#about" style="display: inline-block; white-space: nowrap;">About FLEPRU</a>
              </div>  
        </div>
      </section>
    
    </div>
  </div>

  <section id="section2" class="clr-col-12" style="padding: 0px; border: 0px; margin: 0px;">
    <div class="clr-col-12">
      <div class="clr-row">
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12" style="border: 10px solid white; padding-bottom: 10px; line-height: 2vw; position: relative; background-color: #0d1f2b;">
          <div style="text-align: center; vertical-align: top;">
            <h1 style="color: white; font-weight: bolder;">2024 Annual FLEPRU Training Conference</h1>
          </div>
          <div style="text-align: center; vertical-align: top;">
            <h2 style="color: white; font-weight: bold;">October 7 - 10, 2024</h2>
          </div>
          <div style="height: 1.5vw;"></div>
          <div style="text-align: center; vertical-align: top; line-height: 1.5em;">
            <h2 style="color: white; font-weight: bold; line-height: 1.5em;">
                Rosen Plaza Hotel<br>
                9700 International Drive<br>
                Orlando, FL 32819
            </h2>
          </div>
          <div style="height: 1.5vw;"></div>
          <div style="text-align: center; line-height: 40px;">
            <div class="button1" style="display: inline-block;" onclick="window.open('https://fleprudocs.s3.us-east-2.amazonaws.com/2024_FLEPRU_Conference.pdf','_blank')">Conference</div>
            <div style="display: inline-block; width: 20px;">&nbsp;</div>
            <div class="button1" style="display: inline-block;" onclick="window.open('https://fleprudocs.s3.us-east-2.amazonaws.com/2024_FLEPRU_Retail_Fusion.pdf','_blank')">Fusion Center</div>
            <div style="display: inline-block; width: 20px;">&nbsp;</div>
            <div class="button1" style="display: inline-block;" onclick="window.open('https://fleprudocs.s3.us-east-2.amazonaws.com/2024_FLEPRU_Sponsorship.pdf','_blank')">Sponsor Levels</div>
          </div>
        </div>
        <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12" style="padding: 10px;">
          <img src="..\assets\images\rosen_plaza_front.jpg" style="width: 100%;">
        </div>    
      </div>
    </div>
  </section>

  <section id="section3" class="clr-col-12">
    <div class="clr-col-12">
      <div class="clr-row">
        <div class="clr-col-12" style="text-align: center;">
          <h1 class="header-text-blue">About FLEPRU</h1>
        </div>
        <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 flex" style="text-align: justify;" id="about">
          <div class="text-blue">
            In May of 1987, a group of property recovery detectives within the Tampa Bay area, formed the first property recovery unit in the United States.  They gathered to answer an age-old problem plaguing law enforcement, “How can law enforcement increase the recovery of stolen property and place the guilty in jail?”  The detectives realized that property crimes were continually increasing, and the resources needed for these investigations focused on the suspect(s) and the stolen property.  This group of detectives realized the need to utilize the “Dynamic” approach which focuses on property rather than relying on the arrest of the criminal.  The detectives realized that a “Fence” was a major part of the problem and by using a “Fence” the criminals were able to convert the stolen property to cash.  The “Fence” would bear the burden of selling the property to new property owners.  Sometimes the new owners were unsuspecting of the property’s origin, due to the reputation of the seller.
          </div>
          <div class="text-blue">
            This group recognized that information exchange on a statewide level was necessary to effectively track stolen goods, match recovered goods to victims, and apprehend criminals.  The group decided to formally organize the “Florida Law Enforcement Property Recovery Unit” (FLEPRU), as the group is known, has divided the state into five regions, each having a District Director.
          </div>
          <div class="text-blue">
            Over the last thirty (30) plus years FLEPRU has evolved and broadened the type of investigations we focus on as an organization.  These topics include, Organized Retail Crime (ORC) investigations, auto/marine investigations, social media investigations, economic/cybercrimes investigations, robbery investigations, interviewing & interrogation methods, and topics dealing with the mental health and wellbeing of those within our professions.  FLEPRU holds statewide training seminars and conferences annually.  FLEPRU seminars/conferences utilizes guest instructors such as state legislators, judges, private sector executives and subject matter experts (SME) to discuss the most current trends facing our professions.  FLEPRU membership is open to all law enforcement agencies and personnel; along with approved non-law enforcement investigators, such as retailers, the insurance industry, and various other public and private partnerships.    In addition to the training curriculum FLEPRU brings together many of the top solution providers to highlight new technology relevant to our professions at our annual conference.  Small and large law enforcement agencies alike have benefited from membership in FLEPRU.  FLEPRU has no annual membership fees and is a non-profit corporation.
          </div>
          <div class="text-blue">
            The Goals of FLEPRU are to:
          </div>
          <div class="text-blue">
            <ol class="text-blue" style="list-style-position:outside; padding-left: 30px;">
              <li style="margin-bottom: 5px;">Recruit members from all municipal, county, state, and federal law enforcement agencies within the state of Florida to encourage cooperation.</li>
              <li style="margin-bottom: 5px;">Continue to form public and private partnerships within the law enforcement and retail professions.</li>
              <li style="margin-bottom: 5px;">Provide educational conferences and seminars to law enforcement detectives/investigators, retail partners, and solution providers to stay up to date with current trends and investigative techniques.</li>
              <li style="margin-bottom: 5px;">Raise the recovery percentile of stolen property and returning stolen property to victims.</li>
              <li style="margin-bottom: 5px;">Enact legislation/lobbying to solicit new legislation, and deliver the criminal harsher penalties, assuring the criminal does not convert stolen property to cash.</li>
              <li style="margin-bottom: 5px;">Help establish an FCIC/NCIC computer-based tracking system to track stolen property as well as dealers of stolen property across county and state lines.</li>
            </ol>
          </div>
        </div>
        <!-- <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="text-align: justify;" id="about">
          <h1 class="header-text-blue">About FLEPRU</h1>
          <span>
            <p class="text-blue">In May of 1987, a group of property recovery detectives within the Tampa Bay area, formed the first property recovery unit in the United States.  They gathered to answer an age-old problem plaguing law enforcement, “How can law enforcement increase the recovery of stolen property and place the guilty in jail?”  The detectives realized that property crimes were continually increasing, and the resources needed for these investigations focused on the suspect(s) and the stolen property.  This group of detectives realized the need to utilize the “Dynamic” approach which focuses on property rather than relying on the arrest of the criminal.  The detectives realized that a “Fence” was a major part of the problem and by using a “Fence” the criminals were able to convert the stolen property to cash.  The “Fence” would bear the burden of selling the property to new property owners.  Sometimes the new owners were unsuspecting of the property’s origin, due to the reputation of the seller.</p>
            <p class="text-blue">This group recognized that information exchange on a statewide level was necessary to effectively track stolen goods, match recovered goods to victims, and apprehend criminals.  The group decided to formally organize the “Florida Law Enforcement Property Recovery Unit” (FLEPRU), as the group is known, has divided the state into five regions, each having a District Director.</p>
          </span>
        </div>
        <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12" style="text-align: justify;">
          <h1 style="line-height: 50px; color: #0d1f2b; font-size: 40px;">&nbsp;</h1>
          <span>
            <p class="text-blue">Over the last thirty (30) plus years FLEPRU has evolved and broadened the type of investigations we focus on as an organization.  These topics include, Organized Retail Crime (ORC) investigations, auto/marine investigations, social media investigations, economic/cybercrimes investigations, robbery investigations, interviewing & interrogation methods, and topics dealing with the mental health and wellbeing of those within our professions.  FLEPRU holds statewide training seminars and conferences annually.  FLEPRU seminars/conferences utilizes guest instructors such as state legislators, judges, private sector executives and subject matter experts (SME) to discuss the most current trends facing our professions.  FLEPRU membership is open to all law enforcement agencies and personnel; along with approved non-law enforcement investigators, such as retailers, the insurance industry, and various other public and private partnerships.    In addition to the training curriculum FLEPRU brings together many of the top solution providers to highlight new technology relevant to our professions at our annual conference.  Small and large law enforcement agencies alike have benefited from membership in FLEPRU.  FLEPRU has no annual membership fees and is a non-profit corporation.</p>
            <p class="text-blue">
              The Goals of FLEPRU are to:
            </p>
            <ol class="text-blue" style="list-style-position:outside; padding-left: 30px;">
              <li style="margin-bottom: 5px;">Recruit members from all municipal, county, state, and federal law enforcement agencies within the state of Florida to encourage cooperation.</li>
              <li style="margin-bottom: 5px;">Continue to form public and private partnerships within the law enforcement and retail professions.</li>
              <li style="margin-bottom: 5px;">Provide educational conferences and seminars to law enforcement detectives/investigators, retail partners, and solution providers to stay up to date with current trends and investigative techniques.</li>
              <li style="margin-bottom: 5px;">Raise the recovery percentile of stolen property and returning stolen property to victims.</li>
              <li style="margin-bottom: 5px;">Enact legislation/lobbying to solicit new legislation, and deliver the criminal harsher penalties, assuring the criminal does not convert stolen property to cash.</li>
              <li style="margin-bottom: 5px;">Help establish an FCIC/NCIC computer-based tracking system to track stolen property as well as dealers of stolen property across county and state lines.</li>
            </ol>
          </span>
        </div> -->
        <div class="clr-col-12">
          <p class="text-blue" style="text-align: center; font-weight: bolder;">FLEPRU received the “Exemplary State and Local Award” which is awarded nationally and presented by Rutgers University. The award is based on innovation and achievement. </p>
        </div>
        <div class="clr-col-12" style="height: 20px;"></div>    
      </div>
    </div>
  </section>
</div>