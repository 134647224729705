<div class="clr-row" style="padding:0px; border: 0px; margin: 0px; height: 125px;">
    <div class="clr-col-12" style="background-color: #0d1f2b; height: 125px; text-align: center;">
        <div style="color: white;">P.O. Box 451355 Sunrise, FL 33345</div>
        <div style="color: white;">(954) 410-8760</div>
        <div style="color: white;">Contact@flepru.org</div>
        <!-- <div>
            <img src="../assets/images/facebook_white.png" style="height: 30px;"/>&nbsp;
            <img src="../assets/images/instagram_white.png" style="height: 30px;"/>&nbsp;
            <img src="../assets/images/linkedin_white.png" style="height: 30px;"/>&nbsp;
            <img src="../assets/images/twitter_white.png" style="height: 30px;"/>&nbsp;
        </div> -->
    </div>
</div>