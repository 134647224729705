<nav class="subnav" [clr-nav-level]="1">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': location === 'home'}" routerLink="/" (click)="updateLocation('home')">Home</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': location === 'president'}" routerLink="/president" (click)="updateLocation('president')">President's Message</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': location === 'board'}" routerLink="/board" (click)="updateLocation('board')">Board of Directors</a>
        </li>
        <li class="nav-item">
            <!-- <a class="nav-link" [ngClass]="{'active': location === 'events'}" routerLink="/events" (click)="updateLocation('events')">Events</a> -->
            <a class="nav-link" href="https://web.cvent.com/event/9aa4442e-c2e9-4cd2-a661-1704160a9899/summary" target="_blank">Events</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://fleprudocs.s3.us-east-2.amazonaws.com/2024_FLEPRU_Final_Agenda.pdf" target="_blank">2024 Conference Agenda</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://cvent.me/G9AEP1" target="_blank">Sponsors</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': location === 'contact'}" routerLink="/contact" (click)="updateLocation('contact')">Contact Us</a>
        </li>
    </ul>
</nav>
