import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-president',
  templateUrl: './president.component.html',
  styleUrls: ['./president.component.css']
})
export class PresidentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
