import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  public location: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    if (this.document.location.pathname === 'president') {
      this.location = 'president';
    } else if (this.document.location.pathname === '/') {
      this.location = 'home';
    } else if (this.document.location.pathname === '/board') {
      this.location = 'board';
    } else if (this.document.location.pathname === '/sponsors') {
      this.location = 'sponsors';
    } else if (this.document.location.pathname === '/contact') {
      this.location = 'contact';
    } else if (this.document.location.pathname === '/events') {
      this.location = 'events';
    } else if (this.document.location.pathname === '/fusion') {
      this.location = 'fusion';
    } else {
      this.location = 'home';
    }
  }


  updateLocation(loc) {
    this.location = loc;
  } 
}
